<template>
  <div class="p-2">
    <div class="card">
      <div v-if="teacherExists === true">
        <h1>Les cours de {{ teacher.firstName }} {{ teacher.lastName }}</h1>
        <form class="app-search">
          <div class="form-group w-50 mb-2">
            <label for="per-page">Nombre par page:</label>
            <input
              type="number"
              id="per-page"
              class="form-control"
              step="5"
              min="10"
              max="100"
              v-model="perPage"
            />
          </div>
          <div class="app-search-box">
            <div class="input-group">
              <input
                type="search"
                v-model="search"
                class="form-control"
                placeholder="Rechercher par titre, prix, devise,id..."
              />
            </div>
          </div>
        </form>
      </div>
      <div v-if="teacherExists === false" class="alert alert-warning">
        <p>Enseignant inexistant</p>
      </div>

      <div class="card">
        <table id="basic-datatable" class="table dt-responsive nowrap w-100">
          <thead>
            <tr>
              <th>#</th>
              <th>Cours</th>
              <th>prix</th>
              <th>devise</th>
              <th>duree</th>
              <th>Visible</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="c in paginate" :key="c.id">
              <td>{{ c.id }}</td>
              <td>
                <router-link :to="{ name: 'ShowCourse', params: { id: c.id } }">
                  {{ c.title }}
                </router-link>
              </td>
              <td>{{ c.price }}</td>
              <td>
                {{ c.currency }}
              </td>
              <td>
                {{ c.duration }}
              </td>
              <td>
                <div class="form-check form-switch">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="c.isVisible"
                    @change="toggle(c, 'isVisible')"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="perPage < courses.length"
          class="d-flex justify-content-around border mt-1"
        >
          <button
            :disabled="page === 1"
            class="btn btn-sm btn-info"
            @click="prev"
          >
            <i class="fas fa-arrow-left"></i>
          </button>
          <p>page {{ page }}/{{ totalPages }}</p>
          <button
            :disabled="page == totalPages"
            class="btn btn-sm btn-info"
            @click="next"
          >
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      courses: [],
      perPage: 10,
      page: 1,
      search: "",
      teacher: {
        firstName: "",
        lastName: "",
      },
      teacherExists: null,
    };
  },
  methods: {
    async loadcourses() {
      await this.$http
        .get(`/auth/teacher/course-list/${this.$route.params.id}/`)
        .then((response) => {
          this.courses = response.data;
          if (response.data.length < 1) {
            this.loadTeacher();
          } else {
            this.teacher = response.data[0].teacher;
            this.teacherExists = true;
          }
        })
        .catch((err) => {
          if (err.response.status === 404) this.teacherExists = false;
        });
    },
    async loadTeacher() {
      await this.$http
        .get(`/auth/admin/user/${this.$route.params.id}`)
        .then((response) => {
          if (response.data.groups === "teacher") {
            this.teacher = response.data;
            this.teacherExists = true;
          } else this.teacherExists = false;
        })
        .catch((err) => {
          if (err.response.status === 404) this.teacherExists = false;
        });
    },
    prev() {
      if (this.page > 1) this.page--;
    },
    next() {
      if (this.page < this.totalPages) this.page++;
    },
    async toggle(e, field) {
      const data = { ...e, field: !e[field], teacher: e.teacher.id };
      this.$http.put(`/courses/${e.id}/update/`, data).then(() => {
        this.$store.state.courses.updated = false;
        this.loadcourses();
      });
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.courses.length / this.perPage);
    },
    paginate() {
      let start = (this.page - 1) * this.perPage;
      if (start < 0) start = 1;
      return this.searchResult.slice(start, start + this.perPage);
    },
    searchResult() {
      if (!this.search) return this.courses;
      return this.courses.filter(
        (s) =>
          s.id == this.search ||
          s.title.toLowerCase().includes(this.search) ||
          s.currency.toLowerCase().includes(this.search) ||
          s.price == this.search
      );
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.loadcourses();
    });
  },
};
</script>
<style lang=""></style>
